<template>
    <div>
        <Contact />
    </div>

</template>

<script>
import Contact from '@/components/Form.vue'

export default {
    components:{
        Contact
    }
}
</script>