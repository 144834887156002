<template>
  <div class="file-uploader-form file-uploader-form--contact d-flex align-items-end flex-wrap">
    <div class="d-lg-flex w-100">
      <div class="leftside col-12 col-lg-6 align-self-start text-left text-white pt-5">
          <h3>{{ $store.state.translation.el_contact_form_title }}</h3>
          <p>{{ $store.state.translation.el_contact_form_text }}</p>
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column align-items-center screen-height p-0 pb-md-0 pt-5">
        <div class="col-12 position-relative p-0">
          <div class="form-container w-100">
            <h3 class="text-white p-3 mb-5 text-uppercase" v-if="!formSubmitted || sendingError">{{ $store.state.translation.dm_form_upload_title }}</h3>
            <!-- FORM - user data -->
            <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="!formSubmitted || sendingError">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="row">
                  <div class="col-md-6 pr-md-0">
                    <validation-provider
                        :rules="{'required':true,'min': 2}"
                        :name="`${$store.state.translation.el_form_firstname}`"
                        v-slot="{ failedRules, classes}"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.firstName"
                            class="form-control border-right"
                            type="text"
                            aria-describedby="input-live-help input-live-1-feedback"
                            :placeholder="`${$store.state.translation.el_form_firstname}`"
                            id="surname"
                        />
                      </div>
                      <portal to="firstName">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("surname", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                  <div class="col-md-6 pl-md-0">
                    <validation-provider
                        rules="required|min:2"
                        :name="`${$store.state.translation.el_form_lastname}`"
                        v-slot="{ failedRules, classes }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.lastName"
                            type="text"
                            class="form-control"
                            aria-describedby="input-live-help input-live-1-feedback"
                            :placeholder="`${$store.state.translation.el_form_lastname}`"
                            id="lastname"
                        />
                      </div>
                      <portal to="lastName">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("forname", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 pr-md-0">
                    <validation-provider
                        rules="required|email"
                        :name="`${$store.state.translation.el_form_email}`"
                        v-slot="{  failedRules, classes  }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.email"
                            type="text"
                            class="form-control border-right"
                            :placeholder="`${$store.state.translation.el_form_email}`"
                            name="E-mail cím"
                            id="email"
                        />
                      </div>
                      <portal to="email">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("email", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                  <div class="col-md-6 pl-md-0">
                    <ValidationProvider
                        rules="required"
                        :name="`${$store.state.translation.el_form_phone}`"
                        v-slot="{ failedRules, classes }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.phone"
                            type="text"
                            class="form-control"
                            :placeholder="`${$store.state.translation.el_form_phone}`"
                            name="Telefonszám"
                            id="phone"
                        />
                      </div>
                      <portal to="phone">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("phone", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </ValidationProvider>
                  
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 pr-md-0">
                    <validation-provider
                        rules="required"
                        :name="`${$store.state.translation.el_form_street}`"
                        v-slot="{  failedRules, classes  }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.street"
                            type="text"
                            class="form-control border-right"
                            :placeholder="`${$store.state.translation.el_form_street}`"
                            name="Street and nr."
                            id="street"
                        />
                      </div>
                      <portal to="street">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("street", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                  <div class="col-md-6 pl-md-0">
                    <ValidationProvider
                        rules="required"
                        :name="`${$store.state.translation.el_form_city}`"
                        v-slot="{ failedRules, classes }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.city"
                            type="text"
                            class="form-control"
                            name="City"
                            id="city"
                            :placeholder="`${$store.state.translation.el_form_city}`"
                        />
                      </div>
                      <portal to="city">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("city", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </ValidationProvider>
                  
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 pr-md-0">
                    <validation-provider
                        rules="required"
                        :name="`${$store.state.translation.el_form_postcode}`"
                        v-slot="{  failedRules, classes  }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.postcode"
                            type="text"
                            class="form-control border-right"
                            :placeholder="`${$store.state.translation.el_form_postcode}`"
                            name="Postcode*"
                            id="postcode"
                        />
                      </div>
                      <portal to="postcode">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("postcode", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                  <div class="col-md-6 pl-md-0">
                    <ValidationProvider
                        rules="required"
                        :name="`${$store.state.translation.el_form_country}`"
                        v-slot="{ failedRules, classes }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.country"
                            type="text"
                            class="form-control"
                            name="country"
                            :placeholder="`${$store.state.translation.el_form_country}`"
                            id="country"
                        />
                      </div>
                      <portal to="country">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("country", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </ValidationProvider>
                  
                  </div>
                </div>
              
                <div class="row">
                  <div class="col-md-12">
                    <validation-provider
                        rules="required|min:3"
                        :name="`${$store.state.translation.el_form_message}`"
                        v-slot="{ failedRules, classes }"
                    >
                      <div class="isValidate" :class="classes">
                          <textarea
                              class="form-control--message form-control"
                              name="message"
                              id="message"
                              ref="msgTextarea"
                              :placeholder="`${$store.state.translation.el_form_message}`"
                              v-model="form.message"
                          ></textarea>
                      </div>
                      <portal to="message">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("message", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
              
                </div>
                <router-link class="terms pt-3 px-3 d-block" :to="{name: `PrivacyPolicy_${selectedLangCode}`}" v-html="$store.state.translation.dm_lawtext"></router-link>
                <div v-if="sendingError" class="error error--file justify-content-center">
                  <span >{{ getErrorMessage("errorApi", Object.entries(apiErrorText)[0]) }}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <b-button
                      type="submit"
                      variant="btn btn--submit form-btn"
                  >{{ $store.state.translation.el_form_upload_send }}
                    <img src="../assets/icons/arrow-send.svg" class="img-fluid mb-1">
                  </b-button>
                </div>
                

              </form>
            </validation-observer>

            <div v-if="formSubmitted && !sendingError" class="d-flex justify-content-center align-items-center form-success text-white">
              <h1 class="text-center text-uppercase">{{ $store.state.translation.el_form_success_title }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!--<div v-if="formSubmitted && !sendingError"
           class=" w-100 row uploaded-form-screen justify-content-center align-content-center">
           
           <div class="col-md-6">
            <h1 class="text-center ">{{ $store.state.translation.el_form_success_title }}</h1>
            <h2 class="text-center " >{{ $store.state.translation.el_form_success_subtitle }}</h2>
            <div class="d-flex justify-content-center ">             
              <router-link :to="{ name: 'Home', params:{lang: selectedLangCode}}"
                          @click="backToForm()"
                          class="btn btn--white"
              >{{ $store.state.translation.el_uploaded_screen_home }}
              </router-link>
            </div>
        </div>
      </div>-->
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate/dist/vee-validate.full';
import {setInteractionMode} from "vee-validate";
import errorMessages from "@/assets/errorMessages.json";
import {required, email} from "vee-validate/dist/rules";

setInteractionMode("eager");
// Add a validation rules
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: 'At least 3 characters are required.'
});
extend("email", {
  ...email,
  message: 'Field Email Address must have a valid form.'
});
extend("required", {
  ...required,
  message: '{_field_} is required.'
});


export default {
  name: "FileUploaderForm",
  data() {
    return {            
      show: false,
      formSubmitted: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phone: "",
        street: "",
        city: "",
        postcode: "",
        country: ""
      },
      messageDist: 1,

      baseUrl: process.env.VUE_APP_BASE_URL,
      sendingError: false,
      apiErrorText: {
        required: ""
      },
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    getErrorMessage(fieldName, violation) {
      return violation ? (errorMessages[this.$store.state.language_id][fieldName] && errorMessages[this.$store.state.language_id][fieldName][violation[0]]) ?? violation[1] : undefined;
    },   
    onSubmit() {
      const _this = this;
      const formData = {        
        first_name: _this.form.firstName,
        last_name: _this.form.lastName,
        email: _this.form.email,
        phone: _this.form.phone,
        message: _this.form.message,
        street: _this.form.street,
        city: _this.form.city,
        zip: _this.form.postcode,
        country: _this.form.country        
      }

      _this.post(
          "earthlings_contact",formData,{})
          .then(() => {
            //email to the earthlings team
                _this.post(
                    "/emailsender/7/1",
                    {
                      to: process.env.VUE_APP_EMAIL,
                      cc: process.env.VUE_APP_EMAIL_CC,
                      bcc: process.env.VUE_APP_EMAIL_BCC,
                      data_replace: {
                        first_name: _this.form.firstName,
                        last_name: _this.form.lastName,
                        email: _this.form.email,
                        phone: _this.form.phone,
                        message: _this.form.message,
                        street: _this.form.street,
                        city: _this.form.city,
                        zip: _this.form.postcode,
                        country: _this.form.country                        
                      },
                    },
                    {}).then(() => {
                  _this.sendingError = false;
                }).catch(resp => {
                  console.log('email sender error', resp)                 
                  _this.sendingError = true;
                })
              },
          ).then(() => {
            //email to the user
          _this.post(
            `/emailsender/8/${this.$store.state.language_id}`,
            {
              to: _this.form.email,
              data_replace: {
                first_name: _this.form.firstName,
                last_name: _this.form.lastName
              },
            },
            {}).then(() => {
            _this.formSubmitted = true;
            _this.sendingError = false;
            _this.resetForm()
        }).catch(resp => {
          console.log('email sender error - 2', resp)
          _this.sendingError = true;
        })

      }).catch((resp) => {
        console.log('error 2', resp)       
        _this.sendingError = true;
      })     
    },
    resetForm() {
      this.form = {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phone: "",
        street: "",
        city: "",
        postcode: "",
        country: ""
      },
      this.apiErrorText = {
        required: ""
      }      
    },
    backToForm() {
      this.formSubmitted = false;
    }    
  }
}
</script>
